@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family:'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  --br: 8px;
  --input-paddings: 8px 12px;
  --container: 1280px;
  --container-paddings: 32px;
  --textarea-paddings: 12px;
  --datepicker-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);;
  --dropdown-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  --field-mb: 16px;
  --field-xs-mb: 8px;
  --dropdown-li-padding: 10px 8px;
  --textarea-min-height: 128px;
  --input-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --input-focus-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EBF0FF;
  --input-error-focus-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2;
  --animation: ease-in-out 0.2s;
  --font: 'Inter', sans-serif;
  --header: 72px;
  --budget-bar-small: 52px;
  --budget-bar-big: 128px;
  --budget-bar-big-message: 172px;
  --sidebar: 448px;
  --sidebar-small: 82px;
  --line: 16px;

  --black: #000000;
  --snow: #ffffff;

  --cool-600: #0E2952;


  --gray-blue-500: #4E5BA6;

  --bg: #FCFCFD;

  --gray-25: #FCFCFD;
  --gray-50: #F9FAFB;
  --gray-100: #F2F4F7;
  --gray-200: #EAECF0;
  --gray-300: #D0D5DD;
  --gray-400: #98A2B3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-900: #101828;
  --gray-light-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --gray-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;

  --primary-25: #F9FBFF;
  --primary-50: #F5F7FF;
  --primary-100: #EBF0FF;
  --primary-200: #D6E1FF;
  --primary-300: #9FA9BA;
  --primary-500: #6E7F97;
  --primary-600: #566986;
  --primary-700: #3E5475;
  --primary-800: #263E63;
  --primary-900: #0E2952;
  --primary-1000: #101828;



  --teal-50: #F0FDF9;
  --teal-100: #CCFBEF;
  --teal-200: #B9EFEA;
  --teal-300: #73DED6;
  --teal-400: #30D5C8;
  --teal-500: #14B4A7;
  --teal-600: #0F8C82;
  --teal-700: #107569;
  --teal-800: #125D56;
  --teal-light-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --teal-medium-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #B9EFEA;
  --teal-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCFBEF;


  --red-200: #FECDCA;
  --red-300: #FDA29B;
  --red-500: #F04438;
  --red-600: #D92D20;
  --red-700: #B42318;
  --red-light-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --red-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2;

  --error-25: #FFFBFA;
  --error-50: #FEF3F2;
  --error-100: #FEE4E2;
  --error-300: #FDA29B;
  --error-500: #F04438;
  --error-600: #D92D20;
  --error-700: #B42318;

  --warning-25: #FFFCF5;
  --warning-50: #FFFAEB;
  --warning-100: #FEF0C7;
  --warning-300: #FEC84B;
  --warning-500: #F79009;
  --warning-700: #B54708;
  --warning-600: #DC6803;

  --success-50: #ECFDF3;
  --success-100: #D1FADF;
  --success-500: #12B76A;
  --success-600: #039855;
  --success-700: #027A48;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--container);
  box-sizing: border-box;
  width: 100%;
  padding-left: var(--container-paddings);
  padding-right: var(--container-paddings);
  padding-bottom: var(--container-paddings);
}

.container.wb {
  padding: 0;
}

a {
  color: var(--teal-400);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font);
  text-decoration: none;
}

.pureList {
  padding-left: 10px;
  list-style: none;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 12px !important;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  border: 1px solid var(--gray-100) !important;
  padding: 12px !important;
  font-family: var(--font) !important;
}
.Toastify__toast-container {
  width: auto!important;
}

@media all and (max-width: 1199px) {
  body {
    --container: 768px;
    --container-paddings: 16px;
  }
}

.block-scroll {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Content--after-open {
  animation: scale-up-center 0.2s;
}

.ReactModal__Content--before-close {
  animation: scale-down-center 0.2s;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: auto!important;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
